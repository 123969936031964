import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import Application from './Application';
import Main from './Main'





const jobRequirements = [
    'Knowledge of DNA/RNA/Genomics/Cell Biology/ Flow Cytometry',
    'Experience in Illumina,ASKION, ACEA Biosciences,Fluidigm, Covaris and other key cutting-edge product lines.',
    "Good knowledge of Life Sciences, Cell Biology and Genomics market in respective region",
    'Instrumentation Sales experience is preferred',
    'Track record for Sales of high value Capital instruments in Genomics/Cell Biology/Proteomics/MDx',
  
  ];
  

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
}));

const Jobd= () => {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <Main data={jobRequirements} />
       
      </Section>
      <SectionAlternate innerNarrowed>
      <Application />
      </SectionAlternate>
     
      <Divider />
    </div>
  );
};

export default Jobd;
