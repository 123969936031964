import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { SectionHeader, DescriptionCta } from 'components/molecules';
import { CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const Main = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <DescriptionCta
        title="Field Service Engineer"
        subtitle="Mumbai· Full time"
        primaryCta={
          <Button variant="outlined" color="primary" size="large">
            Refer a friend
          </Button>
        }
        secondaryCta={
          <Button variant="contained" color="primary" size="large">
            Apply now
          </Button>
        }
        align={'left'}
        titleProps={{
          variant: 'h3',
          className: classes.title,
          color: 'textPrimary',
        }}
        subtitleProps={{
          color: 'textPrimary',
        }}
      />
      <Divider className={classes.divider} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <SectionHeader
            title="Who we are"
            subtitle="We believe lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus feugiat elit vitae enim lacinia semper. Cras nulla lectus, porttitor vitae urna iaculis, malesuada tincidunt lectus. Proin nec tellus sit amet massa auctor imperdiet id vitae diam. Aenean gravida est nec diam suscipit iaculis. Praesent urna velit, auctor nec turpis et, vehicula lobortis sem. Vivamus convallis mi sagittis eleifend laoreet. Praesent vitae venenatis enim. Nulla tincidunt felis et lectus rhoncus laoreet."
            align="left"
            data-aos="fade-up"
            titleProps={{
              className: classes.title,
              color: 'textPrimary',
            }}
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />



          <List className={classes.list}>
          <h3> Mandatory Skills</h3>
            {data.map((item, index) => (
              <ListItem disableGutters key={index} data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    src="https://ik.imagekit.io/j6phbjneuop/iconfinder_1814079_approve_check_ok_yes_icon_512px_uLSoNbZvP.png"
                    className={classes.checkBox}
                  />
                </ListItemAvatar>
                <Typography variant="body1" color="textPrimary">
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
         
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <CardBase withShadow className={classes.cardHighlighted}>
              <SectionHeader
                  title="Know about company"
                  subtitle="Checkout Our Story or signup for our newsletter"
                  ctaGroup={[<Button href = '/Ourstory'variant="contained">learn more</Button>]}
                  disableGutter
                  align="left"
                  titleProps={{
                    variant: 'h6',
                    className: classes.textWhite,
                  }}
                  subtitleProps={{
                    variant: 'body1',
                    className: classes.textWhite,
                  }}
                />
              </CardBase>
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <CardBase withShadow>
              <SectionHeader
                  title="Don't see a job for you?"
                  titleVariant="h6"
                  subtitle="Don't worry! Other job openings may interest you."
                  ctaGroup={[
                      <Link to = '/career-listing-minimal'>
                    <Button variant="contained" color="primary">
                      See other vacances
                    </Button>
                    </Link>
                  ]}
                  disableGutter
                  align="left"
                  subtitleProps={{
                    variant: 'body1',
                  }}
                />
              </CardBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Main.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Main;
